//type MiracleColor = typeof MiracleColors[number];

const MiracleColors: Record<string, string> = {
  _AZURECYAN: "rgb(0, 52, 113)",
  _BEIGE: "rgb(186, 143, 84)",
  _BLACK: "rgb(4, 5, 5)",
  _BLUE: "rgb(0, 18, 81)",
  _BROWN: "rgb(49, 3, 3)",
  _CHARTRUESE: "rgb(121, 135, 0)",
  _COBALT: "rgb(0, 2, 6)",
  _DARKBROWN: "rgb(42, 14, 5)",
  _FORESTGREEN: "rgb(2, 18, 4)",
  _GRAY: "rgb(21, 25, 28)",
  _GREEN: "rgb(18, 41, 9)",
  _LAVENDER: "rgb(47, 35, 84)",
  _LIGHTGRAY: "rgb(98, 107, 111)",
  _LIME: "rgb(126, 159, 107)",
  _METALLICGRAY: "rgb(21, 25, 28)",
  _ORANGE: "rgb(192, 21, 0)",
  _PINK: "rgb(123, 5, 26)",
  _PLUM: "rgb(22, 6, 30)",
  _RED: "rgb(127, 0, 6)",
  _SAND: "rgb(91, 71, 33)",
  _SILVER: "rgb(82, 89, 93)",
  _SKY: "rgb(30, 76, 107)",
  _TEAL: "rgb(0, 67, 68)",
  _TROPYELLOW: "rgb(242, 102, 0)",
  _WHITE: "rgb(231, 234, 223)",
  _YELLOW: "rgb(244, 153, 6)",
};

export default MiracleColors;
