import * as BABYLON from "@babylonjs/core";
import * as GUI from "@babylonjs/gui";
import MiracleColors from "../Components/MiracleColors";
import Dropdown from "../Babylon/dropdown";
import { rgbToColor3, ChangeMaterial } from "../Components/Utilities";
import UnitSelection from "../Components/UnitSelection";

export default function Gui3D(scene: BABYLON.Scene) {
  var advancedTexture = GUI.AdvancedDynamicTexture.CreateFullscreenUI(
    "UI",
    true,
    scene
  );
  let currentGroup: Array<string> = [];
  var panel = new GUI.StackPanel();

  let newMaterials: Array<BABYLON.PBRMaterial> = [];
  Object.entries(MiracleColors).map(([key, value]) => {
    const mm = scene.getMaterialByName("PAINT: White");
    let p = mm!.clone(key) as BABYLON.PBRMaterial;
    p.albedoColor = rgbToColor3(value);
    newMaterials.push(p);
  });

  for (let k in MiracleColors) {
    var colorButton = GUI.Button.CreateSimpleButton(
      "Button_" + panel.children.length,
      ""
    );

    colorButton.name = k;
    colorButton.width = "120px";
    colorButton.height = "40px";
    colorButton.background = MiracleColors[k];
    colorButton.paddingBottom = "3px";
    colorButton.onPointerClickObservable.add(
      // eslint-disable-next-line no-loop-func
      (event, state: BABYLON.EventState) => {
        console.log("colorButton pressed: " + state.currentTarget.name);

        const newMat = newMaterials.filter((a) => {
          return a.name === state.currentTarget.name;
        });
        if (currentGroup !== null && newMat !== null) {
          ChangeMaterial(currentGroup, newMat[0]);
        } else {
          console.log(
            "Problem with no objects selected or no mat to replace with. see newMat and currentGroup"
          );
        }
      }
    );
    panel.addControl(colorButton);
  }

  const ddButons = DropdownButtons(advancedTexture, scene);
  ddButons.options.children.forEach((d: GUI.Control) => {
    d.onPointerClickObservable.add((event, state: BABYLON.EventState) => {
      ddButons.button.background = "White";
      ddButons.options.isVisible = false;
      ddButons.button.textBlock!.text = state.currentTarget.name;
      currentGroup = UnitSelection(state.currentTarget.name, scene);
    });
  });

  var myScrollViewer = new GUI.ScrollViewer();
  advancedTexture.addControl(myScrollViewer);
  myScrollViewer.widthInPixels = panel.children[1].widthInPixels + 20;
  myScrollViewer.heightInPixels = 600;
  myScrollViewer.addControl(panel);
  myScrollViewer.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_RIGHT;
  myScrollViewer.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP;
  myScrollViewer.leftInPixels = -75;
  myScrollViewer.topInPixels = 50;
  myScrollViewer.addControl(panel);
}

export function DropdownButtons(
  advancedTexture: GUI.AdvancedDynamicTexture,
  scene: BABYLON.Scene
) {
  var dropdownB = new Dropdown(advancedTexture, "45px", "180px", "", "");
  dropdownB.top = "50px";
  dropdownB.left = "-250";
  let ddButtons = [
    "PVC - Decks",
    "Posts",
    "Accent",
    "Rockite",
    "Rockite Slides",
    "Chameleon Slides",
  ];
  let bID = 1;
  ddButtons.forEach((bName) => {
    dropdownB.addOption(bID, bName, scene, null, null);
    bID++;
  });

  return dropdownB;
}
