import * as GUI from "@babylonjs/gui";

export default class Dropdown {
  private height;
  private width;
  private color;
  private background;
  private advancedTexture;
  private container;
  public options;
  public button;

  constructor(
    advancedTexture: GUI.AdvancedDynamicTexture,
    height: string | number,
    width: string | number,
    color: string,
    background: string
  ) {
    // Members
    this.height = height;
    this.width = width;
    this.color = color || "black";
    this.background = background || "white";

    this.advancedTexture = advancedTexture;

    // Container
    this.container = new GUI.Container();
    this.container.width = this.width;
    this.container.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP;
    this.container.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_RIGHT;
    //this.container.paddingRight = "50px";
    this.container.isHitTestVisible = false;

    // Primary button
    this.button = GUI.Button.CreateSimpleButton("", "Select");
    this.button.height = this.height;
    this.button.background = this.background;
    this.button.color = this.color;
    this.button.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP;

    // Options panel
    this.options = new GUI.StackPanel();
    this.options.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP;
    this.options.top = this.height;
    this.options.isVisible = false;
    this.options.isVertical = true;

    var _this = this;
    this.button.onPointerUpObservable.add(function () {
      _this.options.isVisible = !_this.options.isVisible;
    });

    // add controls
    this.advancedTexture.addControl(this.container);
    this.container.addControl(this.button);
    this.container.addControl(this.options);

    // Selection
    //this.selected = null;
    // this.selectedValue = null;
  }
  get top() {
    return this.container.top as string;
  }
  set top(value: string) {
    this.container.top = value;
  }
  /*    enumerable: true;
      configurable: true; */

  get left() {
    return this.container.left as string;
  }
  set left(value: string) {
    this.container.left = value;
  }
  //   enumerable: true
  //   configurable: true
  get selectedValue() {
    return this.selectedValue;
  }
  set selectedValue(value: string) {
    this.selectedValue = value;
  }
  /*
    get selected() {
      return this.selected;
    }
    set selected(value: GUI.Button) {
      this.selected = value;
    }*/
  addOption(value: any, text: string, scene: any, color: any, background: any) {
    var _this = this;
    var button = GUI.Button.CreateSimpleButton(text, text);
    button.height = _this.height;
    button.paddingTop = "-1px";
    button.background = background || _this.background;
    button.color = color || _this.color;
    /*
      button.onPointerUpObservable.add((event, state) => {
        _this.options.isVisible = false;
        _this.button.children[0].text = text;
        // _this.selected = button;
        _this.selectedValue = text;
      });
  */
    this.options.addControl(button);
  }
  return: any;
}
