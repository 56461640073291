import * as BABYLON from "@babylonjs/core";

export function rgbToColor3(rgb: any) {
  //console.log("rgbToColor3(rgb) rgb = " + rgb);
  let sep = rgb.indexOf(",") > -1 ? "," : " ";
  let rgbArray = rgb.substr(4).split(")")[0].split(sep);
  let clr = [
    Number(rgbArray[0] / 255),
    Number(rgbArray[1] / 255),
    Number(rgbArray[2] / 255),
  ];
  return new BABYLON.Color3(...clr);
}

export function ChangeMaterial(meshes: Array<any>, mat: BABYLON.PBRMaterial) {
  meshes.forEach((d) => {
    d.material = mat;
  });
}

export function ChangeColorofMesh(
  meshes: Array<any>,
  newColor: BABYLON.Color3
) {
  meshes.forEach((d) => {
    d.material!.albedoColor = newColor;
  });
}
