import "./App.css";
import React, { useState } from "react";
import Gui from "./Gui/Gui";
import Babylon from "./Babylon/Babylon";

export default function App() {
  const [value, setValue] = useState<any>();
  const [result, setResult] = useState<any>();

  return (
    <>
      <Gui
        value={value}
        setValue={setValue}
        result={result}
        setResult={setResult}
      />
      <Babylon
        value={value}
        setValue={setValue}
        result={result}
        setResult={setResult}
      />
    </>
  );
}
