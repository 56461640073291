import React, { useState } from "react";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { FaGem, FaHeart } from "react-icons/fa";
import GuiRight from "./Gui.Right";
import MiracleColors from "../Components/MiracleColors";

type Props = {
  value: any;
  result: any;
  setValue: (active: any) => void;
  setResult: (active: any) => void;
};

const Gui = ({ value, setValue, result, setResult }: Props) => {
  const [colorPaletteSelection, setColorPaletteSelection] = useState<
    Array<any>
  >([]);
  const [isVisible, setIsVisible] = useState(true);

  return (
    <>
      <div className="App">
        <GuiRight colorPaletteSelection={colorPaletteSelection} />
      </div>
      ;
    </>
  );
};

export default Gui;

/*


        icons={() => (
          <React.Fragment>
            <button className="p-sidebar-close p-link">
              <span className="p-sidebar-close-icon pi pi-print" />
            </button>
            <button className="p-sidebar-close p-link">
              <span className="p-sidebar-close-icon pi pi-arrow-right" />
            </button>
          </React.Fragment>
        )}
        
        
        
        
        */
