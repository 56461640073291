//import useState hook to create menu collapse state
import React, { useState } from "react";
import { Card } from "primereact/card";
import { slide as Menu } from "react-burger-menu";

//import icons from react icons
import { FaList, FaRegHeart } from "react-icons/fa";
import {
  FiHome,
  FiLogOut,
  FiArrowLeftCircle,
  FiArrowRightCircle,
} from "react-icons/fi";
import { RiPencilLine } from "react-icons/ri";
import { BiCog } from "react-icons/bi";

import "./gui.css";
import "primeflex/primeflex.css";

type Props = {
  colorPaletteSelection: Array<string>;
};

const Colorcard = (colorOne: string | null, colorTwo: string | null) => {
  return (
    <div className="p-grid">
      ;<Card title="title" style={{ background: colorOne }}></Card>;
      {colorTwo ? (
        <Card title="title" style={{ background: colorOne }}></Card>
      ) : (
        ""
      )}
    </div>
  );
};

const GuiRightsidebar = ({ colorPaletteSelection }: Props) => {
  //create initial menuCollapse state using useState hook
  const [menuCollapse, setMenuCollapse] = useState(false);

  //create a custom function that will change menucollapse state from false to true and true to false
  const menuIconClick = () => {
    //condition checking to change state from true to false and vice versa
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
  };

  let colors: string = "";
  for (var c = 0; c < colorPaletteSelection.length - 1; c++) {
    const colorOne = colorPaletteSelection[c][0];
    const colorTwo = colorPaletteSelection[c + 1][0] ?? null;
    colors = colors + Colorcard(colorOne, colorTwo);
  }

  return (
    <>
      <Menu right noOverlay>
        {colors}
      </Menu>
    </>
  );
};

export default GuiRightsidebar;

/*        <a id="home" className="menu-item" href="/">
          Home
        </a>
        <a id="about" className="menu-item" href="/about">
          About
        </a>
        <a id="contact" className="menu-item" href="/contact">
          Contact
        </a>
        <a id="test" className="menu-item" href="/">
          Settings
        </a>
         <div className="p-grid">
          <div className="p-col">{Colorcard("red")}</div>
          <div className="p-col">{Colorcard("red")}</div>
        </div>
        <div className="p-grid">
          <div className="p-col">{Colorcard("red")}</div>
          <div className="p-col">{Colorcard("red")}</div>
        </div>
        
        
        
        
        */
