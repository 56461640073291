import * as BABYLON from "@babylonjs/core";

export default function UnitSelection(grp: string, scene: BABYLON.Scene) {
  let selectedItmes: any = [];
  let selectedLayer = null;

  switch (grp) {
    case "Posts":
      selectedLayer = ["POST_Playarea_1"];

      break;
    case "PVC - Decks":
      selectedLayer = ["PVC_Playarea_1"];
      break;
    case "Accent":
      selectedLayer = [
        "ACCENT_Playarea_1",
        "ACCENT_ENC_Playarea_1",
        "ACCENT_CLIMBER_Playarea_1",
      ];
      break;
    case "Rockite":
      selectedLayer = ["ROCKITE_Playarea_1"];
      break;
    case "Rockite Slides":
      selectedLayer = ["ROCKITE_SLIDE_Playarea_1", "ROCKITE_CANOPY_Playarea_1"];
      break;
    case "Chameleon Slides":
      selectedLayer = [
        "CHAM_CANOPY_Playarea_1",
        "CHAM_EXIT_Playarea_1",
        "CHAM_RIGHT_HIGH_Playarea_1",
        "CHAM_RIGHT_LOW_Playarea_1",
        "CHAM_START_Playarea_1",
      ];
      break;
    case "Swing Seats":
      //selectedLayer = "";
      break;
    case "Roofs":
      //selectedLayer = "ea_1";
      break;
    default:
      throw new Error("SelectedLayer is null. Shouldnt be. Searched on " + grp);
  }
  selectedLayer!.forEach(function (lyr) {
    selectedItmes.push(...scene.getTransformNodeByID(lyr)!.getChildren());
  });
  //ClearHighlightsFromScene(scene, hl);
  // currentGroup = selectedItmes;
  //  selectedItmes.forEach((i: Mesh) => {
  //    hl.addMesh(i, BABYLON.Color3.White());
  // });
  return selectedItmes;
}
