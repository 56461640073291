import React from "react";
import * as BABYLON from "@babylonjs/core";
import "@babylonjs/loaders";
import "@babylonjs/inspector";
import SceneComponent from "../babylon-hook/babylonjs-hook"; // uses above component in same directory
import Gui3D from "../Gui/Gui.3D";

const onSceneReady = (scene: BABYLON.Scene) => {
  const canvas = scene.getEngine().getRenderingCanvas();
  const camera = new BABYLON.ArcRotateCamera(
    "cameraMain",
    0,
    Math.PI / 2 - 0.15,
    25,
    BABYLON.Vector3.Zero(),
    scene
  );

  //Camera
  camera.upperBetaLimit = Math.PI / 2;
  camera.lowerRadiusLimit = 5;
  camera.upperRadiusLimit = 50;
  camera.position = new BABYLON.Vector3(0.15, 4, 12);
  camera.wheelDeltaPercentage = 0.05;
  camera.useBouncingBehavior = true;
  camera.useAutoRotationBehavior = false;
  if (camera.autoRotationBehavior) {
    camera.autoRotationBehavior.zoomStopsAnimation = true;
    camera.autoRotationBehavior.idleRotationWaitTime = 10000;
  }
  camera.attachControl(canvas, true);

  // This targets the camera to scene origin
  camera.setTarget(BABYLON.Vector3.Zero());

  // This attaches the camera to the canvas
  camera.attachControl(canvas, true);
  // end camera

  // This creates a light, aiming 0,1,0 - to the sky (non-mesh)
  var light = new BABYLON.HemisphericLight(
    "light",
    new BABYLON.Vector3(0, 1, 0),
    scene
  );
  light.intensity = 0.7;

  //hard light for shadows
  const sun = new BABYLON.DirectionalLight(
    "sun01",
    new BABYLON.Vector3(-2, -3, 1),
    scene
  );
  sun.shadowEnabled = true;
  sun.autoCalcShadowZBounds = false;
  sun.position = new BABYLON.Vector3(0, 19, 13);

  BABYLON.SceneLoader.Append(
    "/3D/model/",
    "61_44090440047_AISD.gltf",
    scene,
    function () {
      try {
        //ShadowMap
        const shadowGenerator = new BABYLON.ShadowGenerator(1024, sun);
        shadowGenerator.useBlurExponentialShadowMap = true;
        shadowGenerator.blurKernel = 32;

        scene.meshes.forEach((m) => {
          shadowGenerator.addShadowCaster(m);
          m.receiveShadows = true;
          //  (m as BABYLON.Mesh).forceSharedVertices();
        });

        const helper = scene.createDefaultEnvironment({
          enableGroundMirror: false,
          groundShadowLevel: 0.1,
          groundColor: BABYLON.Color3.White(),
          groundYBias: -0.5,
          groundOpacity: 3,
          enableGroundShadow: true,
        });
        helper!.setMainColor(BABYLON.Color3.White());

        /*const hl = new BABYLON.HighlightLayer("hl1", scene);
          hl.blurHorizontalSize = 0.05;
          hl.blurVerticalSize = 0.05;*/
      } catch (error) {
        console.log(error);
      }
    }
  );

  window.addEventListener("keydown", function (evt) {
    // Ctrl+alt+d to show debug overlay
    if (evt.ctrlKey && evt.altKey && evt.key.toLowerCase() === "d") {
      scene.debugLayer.isVisible()
        ? scene.debugLayer.hide()
        : scene.debugLayer.show();
    }
  });

  /* BUTTONS */

  // new color materials to implement user selection
  scene.onReadyObservable.add(() => {
    Gui3D(scene);
  });
};

/**
 * Will run on every frame render.  We are spinning the box on y-axis.
 */
const onRender = (scene: BABYLON.Scene) => {};
type Props = {
  value: any;
  result: any;
  setValue: (active: any) => void;
  setResult: (active: any) => void;
};

const Babylon = ({ value, setValue, result, setResult }: Props) => {
  return (
    <div>
      <SceneComponent
        antialias
        onSceneReady={onSceneReady}
        onRender={onRender}
        id="playground-canvas"
      />
    </div>
  );
};

export default Babylon;
